import axios from "axios";

// const baseURL = "http://localhost:9090/";
// const baseURL = "http://192.168.1.29:8080/";
// const baseURL = "http://5.189.166.187:9090/";
const baseURL = "/";

export const SignInApi = async (data) => {
  const response = await axios.post(`${baseURL}api/signin`, data);
  return response;
};

export const SignUpApi = async (data) => {
  const response = await axios.post(`${baseURL}api/signup`, data);
  return response;
};

export const ChatApi = async (data, token) => {
  const response = await axios.post(`${baseURL}api/protected/chat`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const UserChatByChatIdApi = async (data, token) => {
  const response = await axios.post(
    `${baseURL}api/protected/get-user-chat`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const UserChatHistoryApi = async (data, token) => {
  const response = await axios.post(
    `${baseURL}api/protected/get-user-chat-history`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const FetchCartoonsApi = async (token) => {
  const response = await axios.get(`${baseURL}api/protected/fetch-cartoons`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const FetchGamesApi = async () => {
  const response = await axios.get(`https://portfolio.visiontrek.in/api/games`);
  return response;
};

export const FetchYogaVideosApi = async () => {
  const response = await axios.get(`https://fitofyy.com/videos`);
  // const response = await axios.get(`http://88.99.5.236:5001/fetch-product`);
  return response;
};

export const GetLatestNewsApi = async (data, token) => {
  const response = await axios.post(
    `${baseURL}api/protected/news/get-latest-news`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const GetNewsByCategoryApi = async (data, token) => {
  const response = await axios.post(
    `${baseURL}api/protected/news/get-news-by-category`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const SearchNewsApi = async (data, token) => {
  const response = await axios.post(
    `${baseURL}api/protected/news/search-news`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const RandomQuestionsApi = async () => {
  const response = await axios.get(`${baseURL}api/get-random-questions`);
  return response;
};

export const FetchBlogsApi = async (token) => {
  const response = await axios.get(`${baseURL}api/protected/blogs/get-blogs`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const PostBlogApi = `${baseURL}api/protected/blogs/post-blog`;

export const FetchBlogApi = async (id, token) => {
  const response = await axios.post(
    `${baseURL}api/protected/blogs/get-blog-by-id`,
    {
      id: id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const FetchUserBlogsApi = async (data, token) => {
  const response = await axios.post(
    `${baseURL}api/protected/blogs/fetch-user-blogs`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};
